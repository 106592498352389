export default defineAppConfig({
	ui: {
		primary: "vermilion",
		danger: "#ba2d0b",
		gray: "green-vogue",
		button: {
			inline: "justify-center",
			padding: {
				xs: "px-6 py-2",
				sm: "px-6 py-3",
			},
		},
		input: {
			placeholder: "placeholder-neutral-500/70",
			trailing: {
				padding: { sm: "pe-16" },
			},
		},
		formGroup: {
			label: {
				base: "text-neutral-600 ",
				required: "after:text-vermilion-500/60",
			},
		},
		textarea: {
			placeholder: "placeholder-neutral-500/70",
		},
		radioGroup: {
			wrapper: "mt-2",
			fieldset: "flex gap-10",
		},
	},
});
